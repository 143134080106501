import { RestTurnStepScheduleModel } from '@main-application/boards/interfaces/board';
import { InspectionSummaryFilters } from '@main-application/inspections/models/inspection-summary.model';
import {
  RestDynamicInspectionModel,
  RestInspectionsModel,
} from '@main-application/inspections/models/rest-inspections-model.interface';
import { RestTimezone } from '@main-application/management/pages/system-configuration/components/date-time-configuration/model/timezone';
import { TenantData } from '@shared/interfaces/tenant-data';
import { RestArchivedTurnover, RestTicketModel, RestTurnoverDataModel } from '@shared/interfaces/turnover.interface';

import { TimezoneEntityHelperCommon } from './timezone-entity-common.helper';
import { TimezoneHelper } from './timezone.helper';

export class TimezoneEntityHelper extends TimezoneEntityHelperCommon {
  public static readonly fixTimezoneForTenantDataToClient = (data: TenantData, timezone: RestTimezone) => {
    return super.fixTimezoneForModelToClient(data, timezone, ['servicePackageTrialExpiresOn']);
  };

  public static readonly fixTimezoneForRestInspectionsModelToClient = (
    data: RestInspectionsModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToClient(data, timezone, ['dueDate', 'autoExpiredOn']);
  };

  public static readonly fixTimezoneForRestDynamicInspectionsModelToClient = (
    data: RestDynamicInspectionModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToClient(data, timezone, ['endDate']);
  };

  public static readonly fixTimezoneForRestInspectionsModelToServer = (
    data: RestInspectionsModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToServer(data, timezone, ['dueDate', 'autoExpiredOn']);
  };

  public static readonly fixTimezoneForSummaryStatsToServer = (
    data: InspectionSummaryFilters,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToServer(data, timezone, ['startDate', 'endDate']);
  };

  public static readonly fixTimezoneForExtendExpirationModelToServer = (
    data: { newDate: Date },
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToServer(data, timezone, ['newDate']);
  };

  public static readonly fixTimezoneForExtendExpirationModelToClient = (
    data: RestInspectionsModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToClient(data, timezone, ['autoExpiredOn']);
  };

  public static readonly fixTimezoneForTurnStepScheduleModelToClient = (
    data: RestTurnStepScheduleModel,
    timezone: RestTimezone
  ) => {
    const result = super.fixTimezoneForModelToClient(
      data,
      timezone,
      ['dueDate', 'originalDueDate', 'turnoverDateAvailable'],
      [
        {
          field: 'dateCompleted',
          f: dt => {
            return TimezoneHelper.replaceTimezoneToClient(dt, timezone);
          },
        },
      ]
    );
    return result;
  };
  public static readonly fixTimezoneForTurnStepScheduleModelToServer = (
    data: RestTurnStepScheduleModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToServer(
      data,
      timezone,
      ['dueDate', 'originalDueDate', 'turnoverDateAvailable'],
      [{ field: 'dateCompleted', f: dt => (dt ? new Date(dt).toISOString() : null) }]
    );
  };
  public static readonly fixTimezoneForArchivedTurnoverDataModelToClient = (
    //Has no idea what this function for, maybe should be deleted?
    data: RestArchivedTurnover,
    timezone: RestTimezone
  ) => {
    return this.fixTimezoneForArchivedTurnoverDataModel(
      data,
      timezone,
      TimezoneHelper.addTimeZoneAndKeepOnlyDatePart,
      TimezoneHelper.replaceTimezoneToClient,
      this.fixTimezoneForModelToClient
    );
  };
  public static readonly fixTimezoneForTurnoverDataModelToClient = (
    data: RestTurnoverDataModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToClient(
      data,
      timezone,
      [
        'dateMoveIn',
        'dateMoveOut',
        'pmsMoveOutDate',
        'pmsMoveInDate',
        'lastRentDate',
        'dateAvailable',
        'leaseExecutionDate',
      ],
      [
        {
          field: 'turnStepSchedules',
          f: (list: RestTurnStepScheduleModel[], tz) => {
            list.forEach(e => this.fixTimezoneForTurnStepScheduleModelToClient(e, tz));
            return list;
          },
        },
      ]
    );
  };

  public static readonly fixTimezoneForInspectionDataModelToClient = (
    data: RestInspectionsModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToClient(data, timezone, ['autoExpiredOn', 'dueDate']);
  };

  public static readonly fixTimezoneForTurnoverDataModelToServer = (
    data: RestTurnoverDataModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToServer(
      data,
      timezone,
      ['dateMoveIn', 'dateMoveOut', 'pmsMoveOutDate', 'lastRentDate', 'dateAvailable', 'leaseExecutionDate'],
      [
        {
          field: 'turnStepSchedules',
          f: (list: RestTurnStepScheduleModel[], tz) => {
            list.forEach(e => this.fixTimezoneForTurnStepScheduleModelToServer(e, tz));
            return list;
          },
        },
      ]
    );
  };

  public static readonly fixTimezoneForArchivedTurnoverDataModel = (
    data: RestArchivedTurnover,
    timezone: RestTimezone,
    fVanila: (date: Date | string) => Date,
    fFullDate: (date: Date | string, timezone: RestTimezone) => Date,
    fModel: <T>(data: T, timezone: RestTimezone) => T
  ) => {
    if (!data) return data;
    //Vanile Date
    data.turnoverStartDate = fFullDate(data.turnoverStartDate, timezone);
    data.turnoverEndDate = fFullDate(data.turnoverEndDate, timezone);
    return data;
  };

  public static readonly fixTimezoneForRestTicketDataModel = (data: RestTicketModel, timezone: RestTimezone) => {
    return super.fixTimezoneForModelToClient(data, timezone, ['endDate', 'dueDate']);
  };

  public static readonly fixTimezoneForRestTicketDataModelToServer = (
    data: RestTicketModel,
    timezone: RestTimezone
  ) => {
    return super.fixTimezoneForModelToServer(data, timezone, ['endDate', 'dueDate']);
  };
}
