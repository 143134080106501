import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNumber } from 'lodash';
import * as moment from 'moment';
import {
  BehaviorSubject,
  EMPTY,
  catchError,
  combineLatest,
  from,
  groupBy,
  of,
  startWith,
  switchMap,
  take,
  toArray,
} from 'rxjs';
import { delay, filter, map, mergeMap, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { procentCut } from '@app/utils/procent-cut';
import { AdministrationFacade } from '@main-application/administration/store/facades/administration.facade';
import { InspectionsListConstants } from '@main-application/inspections/components/inspections-list/constants';
import {
  InspectionSummaryAppliedFilters,
  InspectionSummaryExpandedTableData,
  InspectionSummaryModalData,
  InspectionSummaryStatsAssigneeDTO,
  InspectionSummaryStatsResponse,
  InspectionSummaryStatsTemplateDTO,
} from '@main-application/inspections/models/inspection-summary.model';
import { InspectionService } from '@main-application/inspections/services/inspection.service';
import { InspectionsFacade } from '@main-application/inspections/store/inspections.facade';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { getDate } from '@shared/functions/get-date.function';
import { ChartDataItem } from '@shared/interfaces/chart-data-item';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { PendingService } from '@shared/services/pending.service';
import { downloadBlobFile } from '@shared/utils/download/download-blob';
import { debounceSafe$ } from '@shared/utils/rxjs/debounced-auto-save.rxjs.util';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';
import { CalendarHeaderComponent } from '@ui-components/components/calendar-header/calendar-header.component';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import {
  FILTER_PERIOD_RECORD,
  INSPECTION_SUMMARY_CHART,
  INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD,
  INSPECTION_SUMMARY_ERROR,
  SELECT_FILTER_PERIOD_CONFIG,
  SELECT_FILTER_SHOW_BY_CONFIG,
  System,
  Unassigned,
} from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.constant';
import {
  ISelectFilterConfig,
  InspectionSummaryClickFiltering,
  InspectionSummaryShowBy,
  InspectionSummaryShowByRecord,
  InspectionSummaryTableDTO,
  InspectionsSummaryReportModalPeriod,
} from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.model';

@UntilDestroy()
@Component({
  selector: 'app-inspections-summary-report-modal',
  templateUrl: './inspections-summary-report-modal.component.html',
  styleUrls: ['./inspections-summary-report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionsSummaryReportModalComponent extends ComponentAbstract implements OnInit {
  private readonly _currentDate = this.timezoneService.getCurrentDateOnly();

  readonly form = new FormGroup({
    groupByProperty: new FormControl<boolean>(false),
    showByType: new FormControl<ISelectFilterConfig>(SELECT_FILTER_SHOW_BY_CONFIG[1]),
    period: new FormControl<ISelectFilterConfig>(SELECT_FILTER_PERIOD_CONFIG[0]),
  });

  readonly formCustomPeriod = new FormGroup({
    startDate: new FormControl<Date | null>(moment(this._currentDate).subtract(1, 'days').toDate()),
    endDate: new FormControl<Date | null>(moment(this._currentDate).add(1, 'day').toDate()),
  });

  readonly selectPeriodFiltersConfig: ISelectFilterConfig[] = SELECT_FILTER_PERIOD_CONFIG;
  readonly selectShowByFiltersConfig: ISelectFilterConfig[] = SELECT_FILTER_SHOW_BY_CONFIG;

  readonly eIcon = EIcon;
  readonly eColorPalette = EColorPalette;
  readonly header = CalendarHeaderComponent;

  activeStartDate = moment(this._currentDate).subtract(7, 'days');
  activeEndDate = moment(this._currentDate).add(1, 'days');

  readonly summaryReportChartData = new BehaviorSubject<ChartDataItem[] | null>(null);
  inspectionSummaryStatsFormattedChartData: {
    type: ChartDataItem[];
    assignee: ChartDataItem[];
  } = {
    type: [],
    assignee: [],
  };

  readonly summaryReportTableExpandedData = new BehaviorSubject<InspectionSummaryExpandedTableData[]>([]);
  inspectionSummaryStatsFormattedTableExpandedData: {
    type: InspectionSummaryExpandedTableData[];
    assignee: InspectionSummaryExpandedTableData[];
  } = {
    type: [],
    assignee: [],
  };
  expandedRawsIds$ = new BehaviorSubject<number[]>([]);
  templateScrollPointName$ = new BehaviorSubject<{ expanded: string | null; table: string | null }>({
    expanded: null,
    table: null,
  });

  readonly summaryReportTableData = new BehaviorSubject<InspectionSummaryTableDTO[]>([]);
  inspectionSummaryStatsFormattedTableData: {
    type: InspectionSummaryTableDTO[];
    assignee: InspectionSummaryTableDTO[];
  } = {
    type: [],
    assignee: [],
  };

  usersList$ = new BehaviorSubject<RestUserModel[]>([]);

  dateRangeConfig = new DateRange<Date>(
    getDate(moment(this._currentDate).format('MM/DD/YYYY')) || null,
    getDate(moment(this._currentDate).format('MM/DD/YYYY')) || null
  );

  isLoading = new BehaviorSubject<boolean>(true);
  isDataEmpty$ = new BehaviorSubject<boolean>(false);
  showCustomDatePickers$ = new BehaviorSubject(false);

  emptyDashboardMessage = INSPECTION_SUMMARY_ERROR.errorWrong;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  get groupByPropertyControl(): FormControl {
    return this.form.get('groupByProperty') as FormControl;
  }

  get showByTypeControl(): FormControl {
    return this.form.get('showByType') as FormControl;
  }

  get periodControl(): FormControl {
    return this.form.get('period') as FormControl;
  }

  get startDateControl(): FormControl {
    return this.formCustomPeriod.get('startDate') as FormControl;
  }

  get endDateControl(): FormControl {
    return this.formCustomPeriod.get('endDate') as FormControl;
  }

  get appliedFilters(): InspectionSummaryAppliedFilters {
    return {
      portfolioId: this.data.portfolioId,
      isResident: this.data.isResident,
      periodId: this.periodControl.value.id,
      showById: this.showByTypeControl.value.id,
      groupByProperty: this.groupByPropertyControl.value,
    };
  }

  constructor(
    private inspectionsFacade: InspectionsFacade,
    private administrationFacade: AdministrationFacade,
    protected readonly cdr: ChangeDetectorRef,
    private readonly snackbarService: SnackbarService,
    private readonly inspectionService: InspectionService,
    private readonly timezoneService: TimezoneService,
    private readonly pendingService: PendingService,
    private readonly dialogRef: MatDialogRef<InspectionsSummaryReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InspectionSummaryModalData
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.setAppliedFilter();

    this.loadUsersListData();

    this.activateGroupByListener();

    this.isLoading$ = combineLatest([
      this.inspectionsFacade.inspectionSummaryReportLoading$,
      this.pendingService.pending$,
      this.isLoading,
    ]).pipe(
      startWith([true, true, true]),
      map(([a, b, c]) => a || b || c)
    );
  }

  private setAppliedFilter() {
    this.inspectionsFacade.appliedFilter$.pipe(take(1), untilDestroyed(this)).subscribe({
      next: filter => {
        if (filter) {
          this.form.patchValue({
            groupByProperty: filter.groupByProperty,
            showByType: SELECT_FILTER_SHOW_BY_CONFIG.find(a => a.id === filter.showById),
            period: SELECT_FILTER_PERIOD_CONFIG.find(a => a.id === filter.periodId),
          });

          if (filter.periodId === InspectionsListConstants.TimeFrame.Custom) {
            this.showCustomDatePickers$.next(filter.periodId === InspectionsListConstants.TimeFrame.Custom);
            this.startDateControl.patchValue(filter.startDate, { emitEvent: false });
            this.endDateControl.patchValue(filter.endDate, { emitEvent: false });
          }

          this.loadSummaryReportData(filter.groupByProperty, this.periodControl.value);

          this.expandedRawsIds$.next(filter?.expandedPropertyIds || []);

          filter.groupByProperty
            ? this.templateScrollPointName$.next({ expanded: filter.template || null, table: null })
            : this.templateScrollPointName$.next({ expanded: null, table: filter.template || null });
        } else {
          this.loadSummaryReportData();
        }
      },
    });
  }

  private loadSummaryReportData(groupBy = false, period = SELECT_FILTER_PERIOD_CONFIG[0]) {
    combineLatest([
      this.groupByPropertyControl.valueChanges.pipe(startWith(groupBy)),
      this.periodControl.valueChanges.pipe(startWith(period)),
    ])
      .pipe(
        debounceSafe$(),
        switchMap(([groupByProperty, period]) => {
          if (period.id === InspectionsListConstants.TimeFrame.Custom) {
            return combineLatest([
              this.startDateControl.valueChanges.pipe(
                filter(date => !!date),
                startWith(this.startDateControl.value)
              ),
              this.endDateControl.valueChanges.pipe(
                filter(date => !!date),
                startWith(this.endDateControl.value)
              ),
            ]).pipe(
              take(4),
              map(([startDate, endDate]) => ({ startDate, endDate, groupByProperty }))
            );
          } else {
            const { startDate, endDate } = this.calcPeriod(period.label);
            return of({ startDate, endDate, groupByProperty });
          }
        }),
        switchMap(({ startDate, endDate, groupByProperty }) => {
          this.isLoading.next(true);

          const filters = {
            portfolioId: this.data.portfolioId,
            isResident: this.data.isResident,
            startDate,
            endDate,
            groupByProperty,
          };

          this.inspectionsFacade.setAppliedFilters({ ...this.appliedFilters, startDate, endDate });

          return this.inspectionsFacade.getInspectionSummaryReportDataByFilterKey(filters);
        }),
        // tap operator is not compatible here
        switchMap((response: InspectionSummaryStatsResponse) => {
          this.parseSummaryReportData(response);

          return of(EMPTY);
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Failed to fetch summary stats:', error);

          this.snackbarService.error(`Failed to fetch summary stats: ${error.message}`);
          this.isDataEmpty$.next(true);

          return of(EMPTY);
        }),
        delay(200),
        tap(() => this.isLoading.next(false)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private parseSummaryReportData(response: InspectionSummaryStatsResponse): void {
    if (!response || response?.sumByTemplate?.length === 0 || response?.sumByAssignee?.length === 0) {
      this.emptyDashboardMessage = INSPECTION_SUMMARY_ERROR.errorNoData;

      this.isDataEmpty$.next(true);
    } else {
      if (response.sumByTemplate) {
        this.setTableData(response.sumByTemplate, InspectionSummaryShowByRecord.type);
        this.setChartData(response.sumByTemplate, InspectionSummaryShowByRecord.type);
      }
      if (response.sumByAssignee) {
        this.setTableData(response.sumByAssignee, InspectionSummaryShowByRecord.assignee);
        this.setChartData(response.sumByAssignee, InspectionSummaryShowByRecord.assignee);
      }

      if (this.groupByPropertyControl.value) {
        if (response.sumByAssignee) {
          this.setTableExpandedData(response.sumByAssignee, InspectionSummaryShowByRecord.assignee);
        }
        if (response.sumByTemplate) {
          this.setTableExpandedData(response.sumByTemplate, InspectionSummaryShowByRecord.type);
        }
      }

      if (this.showByTypeControl.value?.id === 1) {
        this.summaryReportTableData.next(this.inspectionSummaryStatsFormattedTableData.assignee);
        this.summaryReportChartData.next(this.inspectionSummaryStatsFormattedChartData.assignee);
      } else {
        this.summaryReportTableData.next(this.inspectionSummaryStatsFormattedTableData.type);
        this.summaryReportChartData.next(this.inspectionSummaryStatsFormattedChartData.type);
      }

      this.isDataEmpty$.next(false);
    }
  }

  private loadUsersListData(): void {
    this.administrationFacade.getUsers();

    this.administrationFacade.usersList$
      .pipe(
        tap(u => this.usersList$.next(u)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private setTableData(tableData: Partial<InspectionSummaryStatsAssigneeDTO>[], showBy: InspectionSummaryShowBy): void {
    const mappedTableData = [];
    tableData.forEach(s => {
      const completedProcent = procentCut((s.completed / s.total) * 100);
      const completedLateProcent = procentCut((s.completedLate / s.total) * 100);

      const item = {
        template: null,
        templateId: null,
        assigneeId: null,
        not_started: s.notStarted,
        in_progress: s.inProgress,
        late: s.late,
        expired: s.expired,
        review: s.review,
        completed: s.completed,
        completedProcent: completedProcent,
        completed_late: s.completedLate,
        completedLateProcent: completedLateProcent,
        total: s.total,
      };

      if (showBy === InspectionSummaryShowByRecord.assignee) {
        item.template = this.getAssigneeNameFromUsers(s.assignee);
        item.assigneeId = s.assignee === 0 || item.template === Unassigned ? -1 : s.assignee;
      } else {
        item.template = s.inspectionName;
        item.templateId = s.inspectionTemplateId;
      }

      mappedTableData.push(item);
    });

    if (showBy === InspectionSummaryShowByRecord.type) {
      this.inspectionSummaryStatsFormattedTableData.type = [...mappedTableData];
    } else {
      this.inspectionSummaryStatsFormattedTableData.assignee = [...mappedTableData];
    }
  }

  private setTableExpandedData(
    tableData: Partial<InspectionSummaryStatsAssigneeDTO>[],
    showBy: InspectionSummaryShowBy
  ): void {
    from(tableData)
      .pipe(
        filterNullish$(),
        groupBy(inspection => inspection.propertyId),
        mergeMap(group => group.pipe(toArray())),
        toArray(),
        untilDestroyed(this)
      )
      .subscribe({
        next: (value: Array<InspectionSummaryStatsAssigneeDTO[]>) => {
          const expandedTableData: InspectionSummaryExpandedTableData[] = value
            .reduce(
              (accumValue: InspectionSummaryExpandedTableData[], currentValue: InspectionSummaryStatsAssigneeDTO[]) => {
                const clonedCurrentData = cloneDeep(currentValue); // It needs to be cloned to assign a new field

                const formattedValue = clonedCurrentData.reduce(
                  (acc, cur, i) => {
                    if (i === 0) {
                      acc.expandedTitle = cur.propertyName;
                      acc.propertyId = cur.propertyId;
                    }
                    acc.total.notStarted += cur.notStarted;
                    acc.total.inProgress += cur.inProgress;
                    acc.total.late += cur.late;
                    acc.total.completed += cur.completed;
                    acc.total.completedLate += cur.completedLate;
                    acc.total.expired += cur.expired;
                    acc.total.review += cur.review;
                    acc.total.total += cur.total;

                    cur.inspectionName =
                      showBy === InspectionSummaryShowByRecord.type
                        ? cur.inspectionName
                        : !cur.assignee
                        ? Unassigned
                        : this.getAssigneeNameFromUsers(cur.assignee);

                    if (showBy === InspectionSummaryShowByRecord.assignee) {
                      cur.assigneeId = cur.assignee === 0 || cur.inspectionName === Unassigned ? -1 : cur.assignee;
                      cur.templateId = null;
                    } else {
                      cur.templateId = cur.inspectionTemplateId;
                      cur.assigneeId = null;
                    }

                    acc.data.push(cur);

                    return acc as InspectionSummaryExpandedTableData;
                  },
                  {
                    expandedTitle: '',
                    propertyId: 0,
                    total: {
                      notStarted: 0,
                      inProgress: 0,
                      late: 0,
                      completed: 0,
                      completedLate: 0,
                      expired: 0,
                      review: 0,
                      total: 0,
                    },
                    data: [],
                  }
                );

                accumValue.push(formattedValue);

                return accumValue;
              },
              []
            )
            .sort((a, b) => a.expandedTitle.localeCompare(b.expandedTitle));

          if (showBy === InspectionSummaryShowByRecord.type) {
            this.inspectionSummaryStatsFormattedTableExpandedData.type = [...expandedTableData];
          } else {
            this.inspectionSummaryStatsFormattedTableExpandedData.assignee = [...expandedTableData];
          }

          if (this.showByTypeControl.value.id === 1) {
            this.summaryReportTableExpandedData.next(this.inspectionSummaryStatsFormattedTableExpandedData.assignee);
          } else {
            this.summaryReportTableExpandedData.next(this.inspectionSummaryStatsFormattedTableExpandedData.type);
          }
        },
      });
  }

  private setChartData(tableData: InspectionSummaryStatsTemplateDTO[], showBy: InspectionSummaryShowBy): void {
    const chartData = tableData.reduce(
      (acc, curr) => {
        acc.completed += curr.completed;
        acc.completed_late += curr.completedLate;
        acc.expired += curr.expired;
        acc.review += curr.review;
        acc.in_progress += curr.inProgress;
        acc.late += curr.late;
        acc.not_started += curr.notStarted;
        return acc;
      },
      { in_progress: 0, not_started: 0, completed: 0, completed_late: 0, expired: 0, review: 0, late: 0 }
    );

    const maxChartValue = Math.max(
      ...INSPECTION_SUMMARY_CHART.map((_, index) => chartData[INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD[index]] || 0)
    );

    const formattedData = INSPECTION_SUMMARY_CHART.map((item, index) => ({
      ...item,
      value: chartData[INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD[index]] || 0,
      backgroundColor:
        (chartData[INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD[index]] || 0) === maxChartValue
          ? this.eColorPalette.cGreen3
          : (chartData[INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD[index]] || 0) > 0
          ? this.eColorPalette.cGreen11
          : this.eColorPalette.cGray8,
    }));

    if (showBy === InspectionSummaryShowByRecord.type) {
      this.inspectionSummaryStatsFormattedChartData.type = formattedData;
    } else {
      this.inspectionSummaryStatsFormattedChartData.assignee = formattedData;
    }
  }

  private activateGroupByListener(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(({ groupByProperty, showByType }) => {
      this.isLoading.next(true);

      if (groupByProperty) {
        if (showByType.label.toLowerCase() === InspectionSummaryShowByRecord.assignee) {
          this.summaryReportTableExpandedData.next(this.inspectionSummaryStatsFormattedTableExpandedData.assignee);
        } else {
          this.summaryReportTableExpandedData.next(this.inspectionSummaryStatsFormattedTableExpandedData.type);
        }
      }
    });

    this.showByTypeControl.valueChanges.subscribe(showBy => {
      this.inspectionsFacade.setAppliedFilters({ ...this.appliedFilters, showById: showBy.id });
    });
  }

  private getAssigneeNameFromUsers(id: number): string {
    return id === 1 ? System : this.usersList$.value.find(a => a.id === id)?.displayName || Unassigned;
  }

  private calcPeriod(period: string): InspectionsSummaryReportModalPeriod {
    let startDate = moment(this._currentDate);
    let endDate = moment(this._currentDate).add(1, 'days');

    switch (period) {
      case FILTER_PERIOD_RECORD.sevenDays:
        startDate = moment(this._currentDate).subtract(7, 'days');
        break;

      case FILTER_PERIOD_RECORD.thirtyDays:
        startDate = moment(this._currentDate).subtract(30, 'days');
        break;

      case FILTER_PERIOD_RECORD.threeMonths:
        startDate = moment(this._currentDate).subtract(3, 'months');
        break;

      case FILTER_PERIOD_RECORD.sixMonths:
        startDate = moment(this._currentDate).subtract(6, 'months');
        break;

      case FILTER_PERIOD_RECORD.year:
        startDate = moment(`${moment(this._currentDate).year() - 1}-01-01T00:00:00.000Z`);
        endDate = moment(`${moment(this._currentDate).year() - 1}-12-31T23:59:59.999Z`);
        break;

      case FILTER_PERIOD_RECORD.allTime:
      case FILTER_PERIOD_RECORD.custom:
        startDate = null;
        endDate = null;
        break;
    }

    this.activeStartDate = startDate;
    this.activeEndDate = endDate;

    return {
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate?.toISOString() : null,
    };
  }

  selectedPeriod(event: ISelectFilterConfig): void {
    this.periodControl.patchValue(event);

    this.showCustomDatePickers$.next(event.id === InspectionsListConstants.TimeFrame.Custom);
  }

  selectedShowBy(event: ISelectFilterConfig): void {
    if (event.id === 1) {
      this.showByTypeControl.patchValue(SELECT_FILTER_SHOW_BY_CONFIG[0]);

      this.summaryReportTableData.next(this.inspectionSummaryStatsFormattedTableData.assignee);
      this.summaryReportChartData.next(this.inspectionSummaryStatsFormattedChartData.assignee);
    } else {
      this.showByTypeControl.patchValue(SELECT_FILTER_SHOW_BY_CONFIG[1]);

      this.summaryReportTableData.next(this.inspectionSummaryStatsFormattedTableData.type);
      this.summaryReportChartData.next(this.inspectionSummaryStatsFormattedChartData.type);
    }

    this.isLoading.next(false);
  }

  onDownloadXlsFile(): void {
    this.inspectionService
      .onDownloadInspectionSummary({
        portfolioId: this.data.portfolioId,
        startDate: this.activeStartDate.toISOString(),
        endDate: this.activeEndDate.toISOString(),
        isResident: this.data.isResident,
        groupByProperty: this.groupByPropertyControl.value,
      })
      .pipe(
        catchError(() => of(null)),
        untilDestroyed(this)
      )
      .subscribe({
        next: blob =>
          blob
            ? downloadBlobFile(blob, 'inspection-summary.xlsx')
            : this.snackbarService.error(SnackbarErrorMessage.ErrorFileDownload),
      });
  }

  onSelect(date: Date) {
    if (this.dateRangeConfig?.start && date > this.dateRangeConfig.start && !this.dateRangeConfig.end) {
      this.dateRangeConfig = new DateRange(this.dateRangeConfig.start, date);
      this.activeStartDate = moment(this.dateRangeConfig.start);
      this.activeEndDate = moment(this.dateRangeConfig.end);

      this.trigger.closeMenu();

      this.periodControl.patchValue(SELECT_FILTER_PERIOD_CONFIG[6]);
    } else {
      this.dateRangeConfig = new DateRange(date, null);
    }
  }

  onFilterInspectionList(value: InspectionSummaryClickFiltering): void {
    this.dialogRef.close({
      groupByPropertyId: value?.groupByPropertyId || null,
      templateIds: isNumber(value.templateId) ? [value.templateId] : null,
      assigneeId: isNumber(value.assigneeId) ? [value.assigneeId] : null,
      status: isNumber(value.status) ? [value.status] : null,
      timeframe: this.periodControl.value.id,
      isCustomPeriod: this.periodControl.value.id === InspectionsListConstants.TimeFrame.Custom,
      startDate:
        this.periodControl.value.id === InspectionsListConstants.TimeFrame.Custom
          ? this.activeStartDate.toISOString()
          : null,
      endDate:
        this.periodControl.value.id === InspectionsListConstants.TimeFrame.Custom
          ? this.activeEndDate.toISOString()
          : null,
    });
  }
}
